
import { modalController, actionSheetController, alertController } from "@ionic/vue";
import { defineComponent } from "vue";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import { UserRelationService } from "@/services/mine/UserRelationService";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "Relation",
  components: {
    Header,
    SearchBar,
  },
  data() {
    return {
      relationService: new UserRelationService(),
      list: new Array(0),
      btnIconRight: ["icontianjiaweiyiti"],
      btnSizeRight: ["45px"],
      relationParams: {
        limit: 10,
        offset: 1,
        keyName: "",
      },
      islist: false,
      userType: 0, // 1选择上级   2选择下级
      selectUser: new Array(0),
      $refs: {
        searchRelationRef: SearchBar,
      },
    };
  },
  created() {
    this.initData();
  },

  methods: {
    //页面初始化
    initData() {
      this.islist = true;
      this.getRelationData(this.relationParams, null);
    },
    //下拉刷新
    doRefresh(event: any) {
      this.relationParams.offset = 1;
      //清空查询条件
      this.$refs.searchRelationRef.bindSearchModal(true);
      this.initData();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //下一页数据
    nextPage(event: any) {
      this.relationParams.offset++;
      this.getRelationData(this.relationParams, event);
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
    //执行搜索
    searchHandler(value: any) {
      if (Utils.isInCludeEmoji(value.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      this.relationParams.offset = 1;
      this.relationParams.keyName = value.searchKey;
      this.getRelationData(this.relationParams, null);
    },
    //请求数据
    getRelationData(pamas: any, event: any) {
      this.relationService.getRelationList(pamas).then(
        (r: any) => {
          if (r) {
            const result = r;
            if (result == null || result.length <= 0) {
              // Utils.presentToastWarning("暂无数据，请换个搜索条件吧！");
              if (this.relationParams.offset == 1) {
                this.list = [];
              }
              if (event) {
                event.target.complete();
              }
            } else {
              if (event != null) {
                this.list = this.list.concat(result);
              } else {
                this.list = result;
              }
            }
          } else {
            if (event != null) {
              this.list = [];
            }
          }
        },
        (e: any) => {}
      );
    },
    //打开添加上下级入口
    async onRightClick() {
      const actionSheet = await actionSheetController.create({
        cssClass: "my-custom-class",
        mode: "ios",
        buttons: [
          {
            text: "选择上级领导",
            role: "leader",
            handler: () => {
              this.userType = 1;
              this.selectExecutor();
            },
          },
          {
            text: "选择下属员工",
            role: "staff",
            handler: () => {
              this.userType = 2;
              this.selectExecutor();
            },
          },
          {
            text: "取消",
            role: "cancel",
          },
        ],
      });
      return actionSheet.present();
    },
    //选择上下级（执行人）
    async selectExecutor() {
      this.selectUser = [];
      const modal = await modalController.create({
        component: SelectExecutor,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            type: "executor",
            executorList: "",
          },
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.data.length > 0) {
        for (const i in data.data) {
          const element = data.data[i];
          this.selectUser.push(element);
        }
        this.saveHandler(this.selectUser);
      }
    },
    //保存
    saveHandler(pamas: any) {
      if (this.userType == 1) {
        //添加上级领导
        const pamasArr = [];
        for (let i = 0; i < pamas.length; i++) {
          const itemObj = {
            id: Utils.getUuid,
            leaderUserId: pamas[i].userId,
            subordinateUserId: SystemUtils.loginUser.id,
            username: pamas[i].name,
            isDeleted: false,
          };
          pamasArr.push(itemObj);
        }
        this.saveLader(pamasArr);
        return;
      } else {
        //添加下属员工
        console.log(pamas);
        const pamasArr = [];
        for (let i = 0; i < pamas.length; i++) {
          const itemObj = {
            id: Utils.getUuid,
            leaderUserId: SystemUtils.loginUser.id,
            subordinateUserId: pamas[i].userId,
            username: pamas[i].name,
            isDeleted: false,
          };
          pamasArr.push(itemObj);
        }
        this.saveSubordinate(pamasArr);
      }
    },
    //保存上级关系
    saveLader(pamas: any) {
      this.relationService.insertSuperior(pamas).then(
        (res: any) => {
          if (res) {
            this.relationParams.offset = 1;
            this.relationParams.keyName = "";
            //清空查询条件
            this.$refs.searchRelationRef.bindSearchModal(true);
            this.getRelationData(this.relationParams, null);
            if (res.length > 0) {
              Utils.presentToastWarning("已存在此人员");
            } else {
              Utils.presentToast("添加成功");
            }
          }
        },
        (e: any) => {
          Utils.presentToastWarning(e.message);
        }
      );
    },
    //保存下级关系
    saveSubordinate(pamas: any) {
      this.relationService.insertSubordinate(pamas).then(
        (res: any) => {
          if (res) {
            this.relationParams.offset = 1;
            this.relationParams.keyName = "";
            //清空查询条件
            this.$refs.searchRelationRef.bindSearchModal(true);
            this.getRelationData(this.relationParams, null);
            Utils.presentToastTop("添加成功");
          }
        },
        (e: any) => {
          Utils.presentToastWarning(e.message);
        }
      );
    },
    //删除
    bindDeleteUser(item: any) {
      let pamas = {
        id: item.id,
        isDeleted: true,
        setFields: ["isDeleted"],
      };
      this.deleteAlert(pamas);
    },
    async deleteAlert(pamas: any) {
      const alert = await alertController.create({
        header: "删除",
        message: "您确定要删除吗？",
        mode: "ios",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {},
          },
          {
            text: "确定",
            handler: () => {
              this.relationService.updateFieldsById(pamas).then(
                (res: any) => {
                  if (res) {
                    this.relationParams.offset = 1;
                    this.getRelationData(this.relationParams, null);
                    Utils.presentToast("删除成功");
                  }
                },
                (e: any) => {
                  Utils.presentToastWarning(e.message);
                }
              );
            },
          },
        ],
      });
      await alert.present();
    },
  },
});
